@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap');

*{
  padding: 0;
  margin: 0;
}

#root, html{
  font-family: 'Manrope';
  font-weight: 800;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.cardContainer{
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App{
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-wrap: wrap;
}

body{
  background-color: hsl(218, 23%, 16%);
}

.card{
  width: 375px;
  align-self: center;
  text-align: center;
  background-color: hsl(217, 19%, 24%);
  border-radius: 15px;

  box-shadow: 8px 8px 50px 10px rgba(0, 0, 0, 0.473);
}

.adviceNumber{
  color: hsl(150, 100%, 66%);
  letter-spacing: 3px;
  font-size: 70%;
}

.card > *:not(button){
  margin-top: 30px;
}

.adviceText{
  color:hsl(193, 38%, 86%);
  font-size: 1.5rem;
  margin: 0 20px 0 20px;
}

button{
  transform: translate(0,28px);
  background-color: hsl(150, 100%, 66%);
  border: 0px;
  margin: 0;
  padding: 15px;
  border-radius: 50%;

  transition: box-shadow 0.3s;
}

button:hover{
  box-shadow: -1px 1px 18px 5px hsla(150, 100%, 66%, 0.486);
}

button > img {
  transform: translate(0,2px);
}

@media (min-width:1224px) {

  .card{
    width: 500px;
  }
  
}